<template>
  <b-container class="main-container d-flex justify-content-center mt-5 mb-5">
    <b-row class="main-row">
      <b-col>
        <h2 v-if="$route.params.id">
          Edit: {{ user.full_name }}
        </h2>
        <h2 v-else>
          Add a new user.
        </h2>
        <hr>
        <br>
        <b-form @submit="onSubmit">
          <b-form-group
            id="input-group-first_name"
            label="First name*"
            label-for="input-first_name"
          >
            <b-form-input
              id="input-first_name"
              v-model="user.first_name"
              name="first_name"
              placeholder="Johnny"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-last_name"
            label="Last name"
            label-for="input-last_name"
          >
            <b-form-input
              id="input-last_name"
              v-model="user.last_name"
              name="last_name"
              placeholder="Bravo"
            />
          </b-form-group>
          <b-form-group
            id="input-group-title"
            label="Title*"
            label-for="input-title"
          >
            <b-form-input
              id="input-title"
              v-model="user.title"
              name="title"
              placeholder="(e.g. CEO)"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-email"
            label="Email*"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="user.email"
              type="email"
              name="email"
              placeholder="Enter email here"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-linkedin"
            label="Linkedin"
            label-for="input-linkedin"
          >
            <b-form-input
              id="input-linkedin"
              v-model="user.linkedin"
              type="url"
              name="linkedin"
              placeholder="Enter linkedin(URL) here"
            />
          </b-form-group>
          <b-form-group
            id="input-group-phone"
            label="Phone Number"
            label-for="input-phone"
          >
            <b-form-input
              id="input-phone"
              v-model="user.phone"
              type="tel"
              name="phone"
              placeholder="Enter phone nr here"
            />
          </b-form-group>
          <b-button
            class="mb-4"
            type="submit"
            variant="primary"
          >
            Submit
          </b-button>
          <b-modal
            id="ok-modal"
            class="m-auto"
            size="lg"
            title="Hurray, contact added!"
            centered
          >
            <p class="my-4">
              What would you like to do next?
            </p>
            <template #modal-footer>
              <!-- Emulate built in modal footer ok and cancel button actions -->
              <b-button
                size="sm"
                variant="primary-light"
                @click="addMoreContacts"
              >
                Add More Contacts
              </b-button>
              <b-button
                size="sm"
                variant="primary-light"
                to="/admin/user"
              >
                Go Back To All Users
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="$router.push('/admin/business/create')"
              >
                Next: Create Client
              </b-button>
            </template>
          </b-modal>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'

export default {
  name: 'UserForm',
  data () {
    return {
      user: {},
    }
  },
  created () {
    if (this.$route.params.id) {
      axios.get(`/admin/v2/users/${this.$route.params.id}`)
        .then(({ data }) => {
          this.user = data
        })
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$route.params.id) {
        axios.put(`/admin/v2/users/${this.user.id}`, this.user)
          .then(() =>
            this.$bvModal.show('ok-modal'))
      } else {
        axios.post('/admin/v2/users/create', this.user)
          .then(() => this.$bvModal.show('ok-modal'))
      }
    },
    addMoreContacts () {
      location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  .main-row {
    width: 550px;
  }
  .b-form-tag {
      font-size: 55%;
  }
  .badge-info {
      color: $primary;
      border: 1px solid $primary;
      background: #fff;
  }
}

</style>
